
import Multiselect from "@vueform/multiselect";
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";


// import { VueTelInput } from "vue3-tel-input";
// import "vue3-tel-input/dist/vue3-tel-input.css";
export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
    Multiselect
  },
  setup() {
    const submitButton = ref<HTMLButtonElement | null>(null);
    const sms = ref("");
    const phone = ref(null);
    const image = ref("");
    const message_count = ref("");
    const template_count = ref("");
    const message_content = ref("");
    const store = useStore();
    const numberList = ref([]);
    const temlateList = ref([]);
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);

    const loading = ref<boolean>(false);
    const validNumber = ref<any>();
    const phone_number = ref<string>("");
    const page = ref<string>("");
    const inputOptions = ref<any>({
      styleClasses: "form-control form-control-lg form-control-solid",
    });
    // const value = ref('');
    const registration = Yup.object().shape({
      number: Yup.number()
        .typeError("Please enter 10 digits number")
        .required()
        .label("Phone Number"),
      plivoNumber:Yup.string()
        .typeError("Please select number")
        .required()
        .label("Number"),
      media: Yup.object()
        .shape({
          file: Yup.mixed(),
        })
        .label("Upload file"),
      // plivoNumber: Yup.string().required().label("Select Number"),
    });
    onMounted(() => {
      getNumber();
      getTemplate();
      setCurrentPageBreadcrumbs("Single Message", []);
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        //formData.value = data
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      const id = route.params.id;
      if (id) {
        // getUser(id);
        page.value = "Edit";
      } else {
        page.value = "Add";
      }
    });
    const getNumber = () => {
      var request = {
        url: `number/all`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // phone_number.value = data.data.mobileno;
          numberList.value = data.data;
          var numberData:any = [];
          for(let number of data.data){
            numberData.push({ value: number.phoneNumber, label: number.phoneNumber });
          }
          // console.log(data)
          example3.value.options = numberData
          //options
        }
      });
    };
    
    const messageBox = (e) => {
      //  console.log('sdhhgds',e.length);
      message_count.value = e.length;
    };

    const getTemplateVal = (e) => {
      // alert('test');
      // console.log('data',e);
      var message = sms.value + e;
      sms.value = message;
    };

    const getTemplate = () => {
      var request = {
        url: `template`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          temlateList.value = data.data;
        }
      });
    };

    const testFunction = (event, phoneObject) => {
      if (phoneObject !== undefined) {
        // console.log(event);
        // console.log(phoneObject);
        validNumber.value = phoneObject;
        if (phoneObject.valid) {
          phone_number.value = phoneObject.number;
        } else {
          phone_number.value = "";
        }
      }
    };
    const onSubmitRegister = (values, { resetForm }) => {
      loading.value = true;
      // Clear existing errors
      //loading.value = true;
      // eslint-disable-next-line
      if(submitButton.value){
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      if (values.plivoNumber == undefined) {
        Swal.fire({
          text: "Please select Number!",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-danger",
          },
        });
        loading.value = false;
      } else if (values.message == "") {
        Swal.fire({
          text: "Please Enter message!",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-danger",
          },
        });
        loading.value = false;
      } else {
        // console.log('dsds',values.file[0]);
        // console.log('form value', values.number);
        // console.log('fdfdf',image.value);

        const formData = new FormData();
        // var formDataVal = new FormData();
        formData.append("number", values.number);
        formData.append("plivoNumber", values.plivoNumber);
        formData.append("file", image.value);
        formData.append("message", values.message);
        var request = {
          url: "single-message",
          data: formData,
        };
        store
          .dispatch(Actions.POST, request)
          .then((data) => {
            if (data) {
              resetForm();
              Swal.fire({
                text: "You have successfully send your Message!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
              loading.value = false;
            }
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            loading.value = false;
          });
        //  Swal.fire({
        //   text: "Please select file!",
        //   icon: "warning",
        //   buttonsStyling: false,
        //   confirmButtonText: "Ok, got it!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-danger",
        //   },
        // })
        // console.log('formDataVal',formDataVal);
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      //}, 2000);
      // Swal.fire({
      //   text: "User added successfully!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //   },
      // }).then(function () {
      //   // router.push({ name: "admin-user" });
      // });
    };

    const changefile = (e) => {
      const file = e.target.files[0];
      // const reader = new FileReader();
      // console.log('uploaded file',file.type);
      if (file.type != "image/png" && file.type != "image/jpeg") {
        Swal.fire({
          text: "Please select image only .png .jpeg .jpg!",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-danger",
          },
        });
      } else {
        const file = e.target.files[0];
        image.value = file;
      }
      // reader.onload = function (e) {
      //   if(e.target){
      //   const text = e.target.result;
      // };
    };
    const example3 = ref({
      value: [],
      options: [
      ],
      searchable: true,
    });
    const addTag = () =>{
      console.log(example3.value);
    };
    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      phone,
      testFunction,
      validNumber,
      phone_number,
      inputOptions,
      page,
      changefile,
      image,
      getNumber,
      numberList,
      getTemplate,
      temlateList,
      getTemplateVal,
      sms,
      messageBox,
      message_count,
      template_count,
      example3,
      addTag
    };
  },
});
